import React, { useEffect } from 'react';
import firebase from './services/firebase';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Login } from './pages/Login';
import { Loading } from './pages/Loading';
import { AuthProvider } from './components/AuthProvider';
import { PrivateRoute } from './components/PrivateRoute';
import { Home } from './pages/Home';

function App() {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  /**
   * Ensures that Firebase finishes Auth initialization to determine the state of the user.
   * https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
   *
   * By waiting here, we can use firebase.auth().currentUser downstream to detect auth status.
   * */
  firebase.auth().onAuthStateChanged((_firebaseUser) => {
    setIsInitialized(true);
  });

  return isInitialized ? (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthProvider>
  ) : (
    <Loading />
  );
}

export default App;
