import React, { useCallback, useState } from 'react';
import { Select, Space } from 'antd';
import { TrackingEventState } from '@swyft/swyft-common';


interface Props {
  /** Function that gets executed when the Selector changes */
  onChange: (statuses: TrackingEventState[], filterOption: FilterOption) => void;
}

const MAX_COUNT = 10; // max array of elements for IN firebase operator

export enum FilterOption {
  shipDate = 'Ship Date',
  trackingEvent = 'Tracking Event',
};

export const StatusDateSelector = ({ onChange }: Props) => {
  const [events, _] = useState<TrackingEventState[]>(Object.values(TrackingEventState));
  const [filterOption, setFilteredOption ] = useState<FilterOption>(FilterOption.shipDate);
  const [selectedEvents, setSelectedEvents] = useState<TrackingEventState[]>([TrackingEventState.PENDING]);
  
  const handleSelectFilter = useCallback((value: string) => {
    if (value === FilterOption.shipDate) {
      setFilteredOption(FilterOption.shipDate)
      onChange([], FilterOption.shipDate);
      return;
    }
    setFilteredOption(FilterOption.trackingEvent)
    onChange(selectedEvents, FilterOption.trackingEvent);
  }, [filterOption]);


  const handleSelectTrackingEvent = useCallback(
    (value: TrackingEventState[]) => {

      if (!value.length) {
        onChange([], filterOption);
        return;
      } else {
        if (value.length > MAX_COUNT) {
          onChange(selectedEvents,filterOption);
          return;
        }
        setSelectedEvents(value);
        onChange(value, filterOption);
      }
    },
    [events, selectedEvents,filterOption]
  );

  return (
    <>
      <Space align="end" direction="horizontal" size="middle">
        <Select
          placeholder="Filter By"
          value={filterOption}
          style={{ width: 150 }}
          options={[...Object.values(FilterOption)].map((opt) => {
            return { label: opt, value: opt };
          })}
          onChange={handleSelectFilter}
        />
        {filterOption === FilterOption.trackingEvent ? (
          <>
            <Select
              mode="multiple"
              value={selectedEvents}
              placeholder="Status Date Filter"
              onChange={handleSelectTrackingEvent}
              style={{ width: 200 }}
              allowClear={false}
              options={events.map((event) => {
                return { label: event, value: event };
              })}
            />
          </>
        ) : null}
      </Space>
    </>
  );
};
