import React from 'react';
import {
  TrackingEventState,
  SMSType,
} from '@swyft/swyft-common';
import { Tag, Image, Carousel } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import { LabelStateTag, parseTimestamp, SmsTypeTag } from './helpers/LabelHelpers';
import { Timestamp } from '@firebase/firestore-types';
import { isEmpty } from 'lodash';

export const DEFAULT_PAGINATION_CONFIG = {
  defaultPageSize: 100,
};


export const GENERIC_ERROR_MESSAGE = `Something went wrong`;

export const LABEL_COLUMNS = [
  {
    title: 'First Name',
    dataIndex: ['destination', 'firstName'],
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: ['destination', 'lastName'],
    key: 'lastName',
  },
  {
    title: 'Phone',
    dataIndex: ['destination', 'phone'],
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: ['destination', 'email'],
    key: 'email',
  },
  {
    title: 'Address Line 1',
    dataIndex: ['destination', 'address', 'line1'],
    key: 'addressLine1',
  },
  {
    title: 'Address Line 2',
    dataIndex: ['destination', 'address', 'line2'],
    key: 'addressLine2',
  },
  {
    title: 'Postal Code',
    dataIndex: ['destination', 'address', 'postalCode'],
    key: 'postalCode',
  },
  {
    title: 'City/Town',
    dataIndex: ['destination', 'address', 'city'],
    key: 'city/Town',
  },
  {
    title: 'State/Province',
    dataIndex: ['destination', 'address', 'province'],
    key: 'state/Province',
  },
  {
    title: 'Country',
    dataIndex: ['destination', 'address', 'country'],
    key: 'country',
  },
];


export const SMS_LOGS_TABLE_COLUMNS = [
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    sorter: (row1: any, row2: any) => row1.createdAt - row2.createdAt,
    defaultSortOrder: 'ascend' as SortOrder,
    render: (createdAt: Timestamp) => parseTimestamp(createdAt),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (text: SMSType) => <SmsTypeTag type={text} />,
  },
  {
    title: 'Content',
    dataIndex: 'content',
  },
  {
    title: 'Twilio id',
    dataIndex: 'twilioId',
  },
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Label id',
    dataIndex: 'labelId',
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    render: (updateAt?: Timestamp) => (updateAt ? parseTimestamp(updateAt) : ''),
  },
];

export const TRACKING_EVENTS_TABLE_COLUMNS = [
  {
    title: 'Created at',
    dataIndex: 'timestamp',
    sorter: (row1: any, row2: any) => row1.timestamp - row2.timestamp,
    defaultSortOrder: 'ascend' as SortOrder,
    render: (timestamp: Timestamp) => parseTimestamp(timestamp),
  },
  {
    title: 'State',
    dataIndex: 'state',
    render: (text: TrackingEventState) => <LabelStateTag state={text} />,
  },
  {
    title: 'Details',
    dataIndex: 'details',
  },
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Source type',
    dataIndex: ['source', 'type'],
  },
  {
    title: 'Source metadata',
    dataIndex: ['source', 'metadata'],
  },
];

export const DELIVERY_ATTEMPTS_TABLE_COLUMNS = [
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    sorter: (row1: any, row2: any) => row1.createdAt - row2.createdAt,
    defaultSortOrder: 'ascend' as SortOrder,
    render: (createdAt: Timestamp) => parseTimestamp(createdAt),
  },
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Label id',
    dataIndex: 'labelId',
  },
  {
    title: 'DSP id',
    dataIndex: 'dspId',
  },
  {
    title: 'DSP name',
    dataIndex: 'dspName',
  },
  {
    title: 'Driver id',
    dataIndex: 'driverId',
  },
  {
    title: 'Driver name',
    dataIndex: 'driverName',
  },
  {
    title: 'Completion time',
    dataIndex: 'completionTime',
    sorter: (row1: any, row2: any) => row1.completionTime - row2.completionTime,
    render: (completionTime: Timestamp) => parseTimestamp(completionTime),
  },
  {
    title: 'Success',
    dataIndex: 'success',
    render: (success: boolean) =>
      success ? <Tag color="green">True</Tag> : <Tag color="red">False</Tag>,
  },
  {
    title: 'Route id',
    dataIndex: 'routeId',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
  },
  {
    title: 'Failure reason',
    dataIndex: 'failureReason',
  },
  {
    title: 'Vehicle type',
    dataIndex: 'vehicleType',
  },
  {
    title: 'Package type',
    dataIndex: ['deliveryRate', 'packageType'],
  },
  {
    title: 'Currency',
    dataIndex: ['deliveryRate', 'currency'],
  },
  {
    title: 'Delivery price',
    dataIndex: ['deliveryRate', 'deliveryPrice'],
  },
  {
    title: 'Photos',
    dataIndex: 'photoURLs',
    render: (photoUrls?: string[]) =>
      photoUrls && !isEmpty(photoUrls) ? (
        <div style={{ width: 200, height: 300 }}>
          <Carousel autoplay dotPosition="left">
            {photoUrls.map((url) => (
              <Image height={300} src={url} />
            ))}
          </Carousel>
        </div>
      ) : null,
  },
];

export const SCAN_EVENTS_TABLE_COLUMNS = [
  {
    title: 'Created at',
    dataIndex: 'timestamp',
    sorter: (row1: any, row2: any) => row1.timestamp - row2.timestamp,
    defaultSortOrder: 'ascend' as SortOrder,
    render: (timestamp: Timestamp) => parseTimestamp(timestamp),
  },
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Email',
    dataIndex: 'userEmail',
  },
];
