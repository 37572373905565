import firebase from './firebase';

const TIMEOUTS = {
  // 540 seconds is function timeout setting this to 5 second more
  CREATE_LABELS_FROM_CSV: 545000,
  // 540 seconds is function timeout setting this to 5 second more
  MANUALLY_APPROVE_LABELS: 545000,
  // 540 seconds is function timeout setting this to 5 second more
  CREATE_CLOUD_TASK_BATCHES: 545000,
  // 540 seconds is function timeout setting this to 5 second more
  FINALIZE_LABELS_AS_UNRECEIVED: 545000,
  // 540 seconds is function timeout setting this to 5 second more
  CREATE_ROUTES: 545000,
  // 540 seconds is function timeout setting this to 5 second more
  SYNC_ROUTES_TO_ONFLEET: 545000,
};

export const FUNCTIONS = {
  getGeocodeByLabelId: firebase.functions().httpsCallable('httpsOnCallGetGeocodeByLabelId'),
  getLabelsForCrossdockSortClose: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-getLabelsForCrossdockSortClose'),
  getLabelsForCrossdockSortCloseUnmanaged: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-getLabelsForCrossdockSortCloseUnmanaged'),
  createCloudTaskBatches: firebase
    .functions()
    .httpsCallable('httpsOnCallBatchTasks-createCloudTaskBatches', {
      timeout: TIMEOUTS.CREATE_CLOUD_TASK_BATCHES,
    }),
  createOnFleetTask: firebase.functions().httpsCallable('httpsOnCallCreateOnFleetTask'),
  createRoutes: firebase.functions().httpsCallable('httpsOnCallRouting-createRoutes', {
    timeout: TIMEOUTS.CREATE_ROUTES,
  }),
  cancelRouteOptimization: firebase
    .functions()
    .httpsCallable('httpsOnCallRouting-cancelRouteOptimization'),
  batchCreateLabelsFromCsvForSlug: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-batchCreateLabelsFromCsvForSlug', {
      timeout: TIMEOUTS.CREATE_LABELS_FROM_CSV,
    }),
  updateLabelDataV2: firebase.functions().httpsCallable('httpsOnCallUpdateLabelDataV2'),
  updateLabelState: firebase.functions().httpsCallable('httpsOnCallUpdateLabelState'),
  updateAndValidateDestinationAddress: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-updateAndValidateDestinationAddress'),
  updateManuallyCreatedOnFleetTaskInfo: firebase
    .functions()
    .httpsCallable('httpsOnCallBatchTasks-updateManuallyCreatedOnFleetTaskInfo'),
  manuallyApproveLabels: firebase.functions().httpsCallable('httpsOnCallManuallyApproveLabels', {
    timeout: TIMEOUTS.MANUALLY_APPROVE_LABELS,
  }),
  rescheduleLabels: firebase.functions().httpsCallable('httpsOnCallRescheduleLabels'),
  overrideValidatedDestinationAddressAndGeocode: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-overrideValidatedAddressAndGeocode'),
  deleteLabel: firebase.functions().httpsCallable('httpsOnCallLabels-deleteLabel'),
  markLabelAsReceived: firebase.functions().httpsCallable('httpsOnCallLabels-markLabelAsReceived'),
  finalizeLabelsAsUnreceived: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-finalizeLabelsAsUnreceived', {
      timeout: TIMEOUTS.FINALIZE_LABELS_AS_UNRECEIVED,
    }),
  finalizeLabelsAsUnreceivedUnmanaged: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-finalizeLabelsAsUnreceivedUnmanaged', {
      timeout: TIMEOUTS.FINALIZE_LABELS_AS_UNRECEIVED,
    }),
  assignLabelsToDsp: firebase.functions().httpsCallable('httpsOnCallLabels-updateDspId'),
  syncRecipientToOnfleet: firebase
    .functions()
    .httpsCallable('httpsOnCallOnfleet-syncRecipientToOnfleet'),
  syncDestinationToOnfleet: firebase
    .functions()
    .httpsCallable('httpsOnCallOnfleet-syncDestinationToOnfleet'),
  syncRoutesToOnfleet: firebase
    .functions()
    .httpsCallable('httpsOnCallRouting-syncRoutesToOnfleet', {
      timeout: TIMEOUTS.SYNC_ROUTES_TO_ONFLEET,
    }),
  printPdfLabels: firebase
    .functions()
    .httpsCallable('labels-printPdfLabels'),
};
