import React from 'react';
import { Input, Spin } from 'antd';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider, SearchBox } from '@elastic/react-search-ui';
import { ViewHelpers } from '@elastic/react-search-ui-views';

const projectId = process.env.REACT_APP_FB_PROJECT_ID;

let engineName: string;

if (projectId === 'deep-thought-swyft') {
  engineName = 'labels-prod';
} else if (projectId === 'deep-thought-swyft-staging') {
  engineName = 'labels-staging';
} else {
  // If we are here, then projectId must be 'deepthought-swyft-dev'
  engineName = 'labels-dev';
}

// This Connector is used to connect to Elastic's App Search API
const connector = new AppSearchAPIConnector({
  searchKey: 'search-k4ekdvqq7wcnpkwdyxvoark8', // public read-only search key
  engineName,
  endpointBase: 'https://cfa92e19a8d9457f8e0a544a38e0a8cc.ent-search.us-central1.gcp.cloud.es.io',
  cacheResponses: false,
});

const configurationOptions = {
  apiConnector: connector,
  autocompleteQuery: {
    // Customize the query for autocompleteResults
    results: {
      result_fields: {
        first_name: { snippet: { size: 100, fallback: true } },
        last_name: { snippet: { size: 100, fallback: true } },
        line1: { snippet: { size: 100, fallback: true } },
        line2: { snippet: { size: 100, fallback: true } },
        city: { snippet: { size: 100, fallback: true } },
        province: { snippet: { size: 100, fallback: true } },
        postal_code: { snippet: { size: 100, fallback: true } },
        tracking_number: { snippet: { size: 100, fallback: true } },
        shopify_order_number: { snippet: { size: 100, fallback: true } },
        order_number: { snippet: { size: 100, fallback: true } },
        business_name: { snippet: { size: 100, fallback: true } },
      },
      sort: [{ _score: 'desc' }, { created_at: 'desc' }],
      // # of suggested labels
      resultsPerPage: 5,
    },
  },
};

interface IInputViewProps {
  getAutocomplete: Function;
  getInputProps: Function;
}

interface IAutocompleteViewProps {
  autocompleteResults: boolean;
  autocompletedResults: Object[];
  getItemProps: Function;
  getMenuProps: Function;
  className: string;
}

interface Props {
  onSelectLabel: (labelId: string) => Promise<void>;
}

const getSnippet = (value: any) => {
  return !value || !value.snippet ? '' : String(value.snippet).trim();
};

const renderField = (field: any, comma?: boolean) => {
  return (
    getSnippet(field) && (
      <span
        dangerouslySetInnerHTML={{
          __html: getSnippet(field) + (comma ? ',' : ''),
        }}
        style={{ marginRight: '5px' }}
      />
    )
  );
};

const renderIdField = (label: string, field: any) => {
  return (
    field?.snippet && (
      <span
        dangerouslySetInnerHTML={{
          __html: `<strong>${label}:</strong> ${getSnippet(field)}`,
        }}
      />
    )
  );
};

export const FuzzySearchBar = ({ onSelectLabel }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <SearchProvider config={configurationOptions}>
        <SearchBox
          debounceLength={500}
          autocompleteMinimumCharacters={3}
          autocompleteResults={true}
          inputView={({ getAutocomplete, getInputProps }: IInputViewProps) => (
            <>
              <Input
                {...getInputProps({
                  allowClear: true,
                })}
              />
              {getAutocomplete() ?? ( // getAutocomplete() returns null if there is nothing there yet
                <div className="sui-search-box__autocomplete-container">
                  <Spin />
                </div>
              )}
            </>
          )}
          autocompleteView={({
            autocompletedResults,
            getItemProps,
            getMenuProps,
            className,
          }: IAutocompleteViewProps) => (
            <div
              {...getMenuProps({
                className: ViewHelpers.appendClassName('sui-search-box__autocomplete-container', className),
              })}
              style={{ zIndex: 10 }}
            >
              {autocompletedResults && autocompletedResults.length > 0 && (
                <div>
                  <div className="sui-search-box__section-title">Labels</div>
                  <ul
                    className="sui-search-box__results-list"
                    style={{ maxHeight: '50vh', overflowY: 'scroll' }}
                  >
                    {autocompletedResults.map((result: any, index: any) => {
                      return (
                        <li
                          {...getItemProps({
                            key: result.id.raw,
                            index,
                            item: result,
                          })}
                        >
                          <div style={{ display: 'flex' }}>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {renderField(result['first_name'])}
                                {renderField(result['last_name'], true)}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {renderField(result['line2'])}
                                {renderField(result['line1'], true)}
                                {renderField(result['city'], true)}
                                {renderField(result['province'], true)}
                                {renderField(result['postal_code'])}
                              </div>
                              <div>{renderIdField('Business name', result['business_name'])}</div>
                              <div>
                                {renderIdField('Tracking number', result['tracking_number'])}
                              </div>
                              <div>
                                {renderIdField(
                                  'Shopify order number',
                                  result['shopify_order_number']
                                )}
                              </div>
                              <div>{renderIdField('Order number', result['order_number'])}</div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
          onSelectAutocomplete={async (selection: any) => {
            if (selection) {
              await onSelectLabel(selection.id.raw);
            }
          }}
        ></SearchBox>
      </SearchProvider>
    </div>
  );
};
