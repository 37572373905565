import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import { getAllDeliveryServicePartners } from '../services/firestore';
import { IDeliveryServicePartner } from '@swyft/swyft-common';

interface Props {
  onChange: (dspId: string) => void;
}

/**
 * Reusable component to fetch and select DSP ids and names
 * @param onChange Callback for the parent component when a slug is selected / deselected
 */
export const DspSelector = ({ onChange }: Props) => {
  const [dsps, setDsps] = useState<IDeliveryServicePartner[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getDsps = async () => {
      setIsLoading(true);
      try {
        const dsps = await getAllDeliveryServicePartners();
        setDsps(dsps.sort((a, b) => (a.name > b.name ? 1 : -1)));
      } catch (err) {
        console.error(err);
        message.error('An error occured when loading dsps! See console.');
      } finally {
        setIsLoading(false);
      }
    };
    getDsps();
  }, []);

  const handleDspSelect = (id: string) => {
    onChange(id);
  };

  return (
    <Select
      placeholder="DSPs"
      options={dsps.map((dsp) => ({ label: dsp.name, value: dsp.id }))}
      filterOption={true}
      loading={isLoading}
      onChange={handleDspSelect}
      allowClear={true}
      style={{ width: 200 }}
    />
  );
};
