import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
} from '@firebase/firestore-types';
import { IUserBase } from '@swyft/swyft-common';

/** Converter used to add our typings to firestore DocumentData */
export const userConverter: FirestoreDataConverter<IUserBase> = {
  toFirestore: (user: IUserBase): DocumentData => {
    return user;
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IUserBase => {
    const data = snapshot.data(options)!;
    return {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      permissions: data.permissions,
      type: data.type,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};
