import React from 'react';
import { Select, Tag, Typography } from 'antd';
import {
  TrackingEventState,
  ISmartyStreetsPrecision,
  ILabelV2,
  SMSType,
  DATE_FORMAT,
  CountryISO2,
  IDeliveryServicePartner,
  ITrackingEvent,
} from '@swyft/swyft-common';
import { blue, yellow, green, red, grey, orange, purple } from '@ant-design/colors';
import { Timestamp } from '@firebase/firestore-types';
import moment from 'moment';
import { isValidationPrecisionPass } from '@swyft/swyft-common/lib/helpers/LabelHelpers';

const { Option } = Select;

interface LabelStateTagProps {
  state: TrackingEventState;
  style?: { [propName: string]: any };
}

/**
 * Generates a colored tag for a label state
 * @param labelState the state of the label
 */
export const LabelStateTag = ({ state, style }: LabelStateTagProps) => {
  const labelStateToColorMap: {
    [key: string]: string | undefined;
  } = {
    [TrackingEventState.PENDING]: yellow.primary,
    [TrackingEventState.CONFIRMED]: blue.primary,
    [TrackingEventState.ASSIGNED]: blue.primary,
    [TrackingEventState.RECEIVED]: blue.primary,
    [TrackingEventState.IN_TRANSIT]: green.primary,
    [TrackingEventState.ARRIVING]: green.primary,
    [TrackingEventState.DELIVERED]: green.primary,
    [TrackingEventState.FAILED]: red.primary,
    [TrackingEventState.DELETED]: grey[grey.length - 1],
    [TrackingEventState.SCHEDULED_FOR_REDELIVERY]: yellow.primary,
    [TrackingEventState.RETURNING_TO_SENDER]: yellow.primary,
    [TrackingEventState.RETURNED_TO_SENDER]: orange.primary,
    [TrackingEventState.NOT_RECEIVED]: grey.primary,
    [TrackingEventState.PROBLEM]: orange.primary,
    [TrackingEventState.STORAGE]: yellow.primary,
  };
  const tagColor = labelStateToColorMap[state] || purple.primary;
  const fontColor = tagColor === yellow.primary ? '#000' : '#fff'; // Yellow is low contrast with white text, so we change the text color to black
  return (
    <Tag style={style} color={tagColor}>
      <span style={{ color: fontColor }}>
        {state.replace('RETURNED_TO_SENDER', 'RETURNED').split('_').join(' ')}
      </span>
    </Tag>
  );
};

export const PrecisionTag = ({
  addressPrecision,
  geocodePrecision,
}: {
  addressPrecision?: ISmartyStreetsPrecision;
  geocodePrecision?: ISmartyStreetsPrecision;
}) => {
  if (!isValidationPrecisionPass(addressPrecision, geocodePrecision)) {
    return <Tag color="red">Fail</Tag>;
  } else if (addressPrecision === ISmartyStreetsPrecision.MANUAL) {
    return <Tag color="green">{ISmartyStreetsPrecision.MANUAL}</Tag>;
  } else {
    return <Tag color="green">Pass</Tag>;
  }
};

/**
 * Returns a "rank" on the label's address validation. Higher rank is "worse". Passing rank is 0.
 * Rank of 2 = no validated data
 * Rank of 1 = address is inaccurate
 * Rank of 0 = Validation passed!
 */
export const getAddressRank = (label: ILabelV2): number => {
  const { validatedAddressV2 } = label.destination || {};
  if (!validatedAddressV2) {
    return 2;
  }
  const { addressPrecision, geocodePrecision } = validatedAddressV2;
  if (!isValidationPrecisionPass(addressPrecision, geocodePrecision)) {
    return 1;
  }
  return 0;
};

/**
 * Determines if a label is invalid for Onfleet sync.
 *
 * todo: Implement this in backend
 */
export const isValidLabelForOnfleet = (label: ILabelV2) => {
  if (isFailed(label)) {
    return false;
  } else if (isAssignedToDsp(label)) {
    return false;
  } else if (isReturningToSender(label)) {
    return false;
  } else if (isReturnedToSender(label)) {
    return false;
  } else if (!label.manuallyApproved) {
    return false;
  } else if (label.state === TrackingEventState.NOT_RECEIVED) {
    return false;
  } else if (label.state === TrackingEventState.PROBLEM) {
    return false;
  }
  return true;
};

export const isFailed = (label: ILabelV2) => label.state === TrackingEventState.FAILED;

export const isAssignedToDsp = (label: ILabelV2) => label.dspId;

export const isReturningToSender = (label: ILabelV2) =>
  label.state === TrackingEventState.RETURNING_TO_SENDER;

export const isReturnedToSender = (label: ILabelV2) =>
  label.state === TrackingEventState.RETURNED_TO_SENDER;

export const getTrackingEventStateOptions = () => {
  const trackingEventStateOptions = [];
  for (const eventState in TrackingEventState) {
    // exluding deleted state, because it needs to go through delete callable
    if (eventState !== TrackingEventState.DELETED) {
      trackingEventStateOptions.push(
        <Option key={eventState} value={eventState}>
          {eventState.replace('RETURNED_TO_SENDER', 'RETURNED')}
        </Option>
      );
    }
  }
  return trackingEventStateOptions;
};

export const getLabelId = (label: ILabelV2): string => {
  return label.id;
};


interface SmsTypeTagProps {
  type: SMSType;
}

/**
 * Generates a colored tag for an SMS type
 * @param type the type of the SMS
 */
export const SmsTypeTag = ({ type }: SmsTypeTagProps) => {
  const smsTypeToColorMap = {
    [SMSType.SCHEDULED_FOR_DELIVERY]: blue.primary,
    [SMSType.NEXT_FOR_DELIVERY]: green.primary,
    [SMSType.ARRIVING]: green.primary,
    [SMSType.DELIVERED]: green.primary,
    [SMSType.DELIVERED_SURVEY]: green.primary,
    [SMSType.FAILED]: red.primary,
    [SMSType.SCHEDULED_FOR_REDELIVERY]: yellow.primary,
    [SMSType.RETURNING_TO_SENDER]: yellow.primary,
    [SMSType.RECEIVED]: blue.primary,
    [SMSType.ERROR]: red.primary,
  };

  return <Tag color={smsTypeToColorMap[type]}>{type.split('_').join(' ')}</Tag>;
};

/**
 * Converts timestamp to readable date format (e.g. 9:30 AM, 22 Apr 2021 )
 * @param timestamp a timestamp
 */
export const parseTimestamp = (timestamp: Timestamp, format?: string) => {
  return moment(timestamp.toDate()).format(format || DATE_FORMAT.READABLE_TIMESTAMP);
};

/** Returns FSA for Canadian addresses and ZIP5 for US addresses for the given label */
export const getLabelPostalZone = (label: ILabelV2) => {
  const prefixLength = label.destination.validatedAddressV2?.country === CountryISO2.USA ? 5 : 3;

  const postalCode = label.destination.validatedAddressV2?.postalCode || '';

  return postalCode.trim().toUpperCase().substring(0, prefixLength);
};

export const GetDspNames = (value: { value: string; dsps: IDeliveryServicePartner[] }) => {
  const dspNamesToRender = value.dsps.filter((dsp) => value.value.includes(dsp.id));

  return (
    <ul>
      {dspNamesToRender.map((dspNames) => {
        return <li key={dspNames.id}>{dspNames.name}</li>;
      })}
    </ul>
  );
};

export const GetDspName = (value: { value: string; dsps: IDeliveryServicePartner[] }) => {
  const dspNames = value.dsps.filter((dsp) => value.value === dsp.id);

  return (
    <text>{dspNames.length === 0 ? '' : dspNames[0].name}</text>
  );
};

export const getDspName = (dspId: string, dsps: IDeliveryServicePartner[]) => {
  const dspNames = dsps.filter((dsp) => dspId === dsp.id);

  return dspNames.length === 0 ? '' : dspNames[0].name
};

export const getDeliveredDspName = (dsps: IDeliveryServicePartner[],label: ILabelV2, deliveredTrackingEvent?: ITrackingEvent, ) => {
  
  if(deliveredTrackingEvent){
    const dspId = deliveredTrackingEvent.dspId || label.dspId

    if(dspId){
      return getDspName(dspId, dsps)
    }
  } 
};
