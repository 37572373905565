import React from 'react';
import {
  Button,
  DatePicker,
  Table,
  Space,
  Popconfirm,
  Row,
  Col,
  Statistic,
  message,
  Modal,
  Tag,
  Tabs,
  Select,
  Typography,
} from 'antd';
import {
  EditTwoTone,
  DeleteTwoTone,
  PrinterTwoTone,
  PrinterOutlined,
  FileSearchOutlined,
  FieldTimeOutlined,
  DeliveredProcedureOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  FullscreenOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import {
  DATE_FORMAT,
  ILabelV2,
  ITrackingEvent,
  TrackingEventState,
  IDeliveryServicePartner,
} from '@swyft/swyft-common';

import { uniq, capitalize, get, chunk, startCase } from 'lodash';
import moment, { Moment, unitOfTime } from 'moment';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DEFAULT_PAGINATION_CONFIG, LABEL_COLUMNS } from '../consts';
import { EditLabelModal } from '../components/EditLabelModal';
import { LabelDetailsDrawer } from '../components/LabelDetailsDrawer';
import {
  getLabelId,
  LabelStateTag,
  getTrackingEventStateOptions,
  getLabelPostalZone,
  getDspName,
  GetDspNames,
} from '../helpers/LabelHelpers';
import { FuzzySearchBar } from '../components/FuzzySearchBar';
import { FUNCTIONS } from '../services/functions';
import {
  getLabelById,
  getLabelsForShipDateRangeAndSlugs,
  getLabelsForTrackingEventStatuses,
  getTrackingEvents,
  getAllDeliveryServicePartners,
} from '../services/firestore';

import { MerchantSlugSelector } from '../components/MerchantSlugSelector';
import { DspSelector } from '../components/DspSelector';
import { StatusDateSelector, FilterOption } from '../components/StatusDateSelector';
import {
  CrossdockSelector,
  ICrossdockSelectorOnChangeParam,
} from '../components/CrossdockSelector';
import { TimezoneSelector, timezoneMapping } from '../components/TimezoneSelector'
import { Timestamp } from '@firebase/firestore-types';
import { exportLabelsCSV } from '../helpers/fileExportHelpers';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Text } = Typography;

const ASSIGN_TO_DSP_BATCH_COUNT = 20;
const MIN_ASSIGN_TO_DSP_BATCH = 1000;

enum DATE_RANGE_TYPE {
  DATE = 'date',
  WEEK = 'week',
  MONTH = 'month',
  CUSTOM = 'custom',
}

interface UrlSearchParams {
  org: string;
  shipDate: string;
}

interface State {
  merchantSlugs: string[];
  selectedMerchantSlugs: string[];
  selectedDspId: string;
  selectedAssignedDspId: string;
  selectedTrackingEvents: string[];
  timezone: string;
  filterOption: FilterOption,
  rescheduleShipDate: Moment;
  modifyLabelsState: TrackingEventState;
  dateRangeStart: Moment;
  dateRangeEnd: Moment;
  labelsDateStart: Moment;
  labelsDateEnd: Moment;
  dateRangeType: DATE_RANGE_TYPE;
  isLoadingTable: boolean;
  isLoadingMerchantSlugs: boolean;
  isEditModalVisible: boolean;
  isLabelDetailsDrawerVisible: boolean;
  isPrintingLabels: boolean;
  isDownloadingCsv: boolean;
  isRescheduleLabelsModalVisible: boolean;
  isReschedulingLabels: boolean;
  isModifyingLabelsState: boolean;
  isModifyLabelsStateModalVisible: boolean;
  isAssigningToDsp: boolean;
  isAssignToDspModalVisible: boolean;
  labels: ILabelV2[];
  filteredLabels: ILabelV2[];
  selectedLabels: ILabelV2[];
  selectedLabel: ILabelV2;
  trackingProblemEvents: { [key: string]: ITrackingEvent };
  rescheduleLabelErrors: any[];
  filteredFields: any;
  selectedCrossdockId: string;
  dsps: IDeliveryServicePartner[];
}

class LabelsBase extends React.Component<RouteComponentProps<UrlSearchParams>, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      merchantSlugs: [],
      rescheduleLabelErrors: [],
      selectedLabels: [],
      labels: [],
      filteredLabels: [],
      trackingProblemEvents: {},
      selectedMerchantSlugs: [],
      selectedDspId: '',
      selectedAssignedDspId: '',
      selectedTrackingEvents: [],
      timezone: timezoneMapping.Los_Angeles,
      filterOption: FilterOption.shipDate,
      rescheduleShipDate: moment(),
      modifyLabelsState: TrackingEventState.NOT_RECEIVED,
      dateRangeStart: moment(),
      dateRangeEnd: moment(),
      labelsDateStart: moment(),
      labelsDateEnd: moment(),
      dateRangeType: DATE_RANGE_TYPE.DATE,
      selectedLabel: {} as ILabelV2,
      filteredFields: {},
      isEditModalVisible: false,
      isLabelDetailsDrawerVisible: false,
      isPrintingLabels: false,
      isDownloadingCsv: false,
      isRescheduleLabelsModalVisible: false,
      isReschedulingLabels: false,
      isModifyingLabelsState: false,
      isModifyLabelsStateModalVisible: false,
      isAssigningToDsp: false,
      isAssignToDspModalVisible: false,
      isLoadingTable: false,
      isLoadingMerchantSlugs: false,
      selectedCrossdockId: '',
      dsps: [],
    };
  }

  async componentDidMount() {
    // Check for query params
    const queryParams = queryString.parse(location.search);
    const { org, shipDate } = queryParams;
    if (org && shipDate) {
      this.setState(
        {
          selectedMerchantSlugs: org.toString().split(','),
          dateRangeStart: moment(shipDate, 'YYYY-MMM-DD'),
          dateRangeEnd: moment(shipDate, 'YYYY-MMM-DD'),
        },
        this.handleSearch
      );
    } else if (get(this.props, ['location', 'state', 'labelIds'])) {
      this.handleStatePassedLabelIds();
    }
    this.getDsps();
  }

  setSelectedMerchantSlugs = (selectedMerchantSlugs: string[]) => {
    this.setState({ selectedMerchantSlugs });
  };

  setSelectedDspId = (selectedDspId: string) => {
    this.setState({ selectedDspId });
  };

  setSelectedAssignedDspId = (selectedAssignedDspId: string) => {
    this.setState({ selectedAssignedDspId });
  };

  setSelectedCrossdockId = (crossdockData: ICrossdockSelectorOnChangeParam) => {
    this.setState({ selectedCrossdockId: crossdockData.id });
  };

  setSelectedStatusDateEvent = (events: TrackingEventState[], filterOption: FilterOption) => {
    this.setState({ selectedTrackingEvents: events , filterOption});
  };

  setTimezone = (timezone: string) => {
    this.setState({ timezone });
  }

  getProblemTrackingEvents = async (label: ILabelV2): Promise<ITrackingEvent[]> => {
    let problemEvents = await getTrackingEvents(label.merchantId, label.id);
    problemEvents = problemEvents
      .filter((e) => e.state === TrackingEventState.PROBLEM)
      .sort((a, b) => ((a.timestamp as Timestamp) > (b.timestamp as Timestamp) ? 1 : -1));
    return problemEvents;
  };

  getDsps = async () => {
    try {
      const dsps = await getAllDeliveryServicePartners();
      this.setState({ dsps: dsps.sort((a, b) => (a.name > b.name ? 1 : -1)) });
    } catch (err) {
      console.error(err);
      message.error('An error occured when loading dsps! See console.');
    }
  };

  getLabelTableColumns = () => {
    const { filteredFields } = this.state;

    return [
      {
        title: 'Merchant',
        dataIndex: 'merchantSlug',
        key: 'merchantSlug',
        filteredValue: filteredFields.merchantSlug || null,
        // generating filter option list from the merchant slugs of the loaded labels
        filters: uniq(this.state.labels.map((label) => label.merchantSlug))
          .sort()
          .map((merchantSlug: string) => ({ text: merchantSlug, value: merchantSlug })),
        onFilter: (value: any, row: ILabelV2) => row.merchantSlug === value,
        filterSearch: true,
      },
      {
        title: 'TrackingId',
        dataIndex: ['trackingNumber'],
        key: 'trackingNumber',
        filteredValue: filteredFields.trackingNumber || null,
        filters: uniq(this.state.labels.map((label) => label.trackingNumber))
          .sort()
          .map((trackingNumber: string) => ({ text: trackingNumber, value: trackingNumber })),
        onFilter: (value: any, row: ILabelV2) => row.trackingNumber === value,
        filterSearch: true,
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        render: (text: TrackingEventState) => <LabelStateTag state={text} />,
        filteredValue: filteredFields.state || null,
        filters: Object.keys(TrackingEventState).map((state) => ({
          text: state.replace('RETURNED_TO_SENDER', 'RETURNED'),
          value: state,
        })),
        onFilter: (value: any, row: ILabelV2) => row.state === value,
      },
      {
        title: 'Received',
        dataIndex: 'received',
        key: 'received',
        render: (received: any) =>
          Boolean(received) ? <Tag color="green">True</Tag> : <Tag color="red">False</Tag>,
        filteredValue: filteredFields.received || null,
        filters: [
          { text: 'Received', value: true },
          { text: 'Not received', value: false },
        ],
        onFilter: (value: any, row: ILabelV2) => !!row.received === value,
      },
      {
        title: 'Ship Date',
        dataIndex: ['shipDate'],
        key: 'shipDate',
      },
      // {
      //   title: 'First Name',
      //   dataIndex: ['destination', 'firstName'],
      //   key: 'firstName',
      // },
      // {
      //   title: 'Last Name',
      //   dataIndex: ['destination', 'lastName'],
      //   key: 'lastName',
      // },
      // {
      //   title: 'Address Line 1',
      //   dataIndex: ['destination', 'address', 'line1'],
      //   key: 'addressLine1',
      // },
      {
        title: 'Postal Code',
        dataIndex: ['destination', 'validatedAddressV2', 'postalCode'],
        key: 'postalCode',
        filteredValue: filteredFields.postalCode || null,
        filters: uniq(this.state.labels.map(getLabelPostalZone))
          .sort()
          .map((postalZone: string) => ({
            text: postalZone,
            value: postalZone,
          })),
        onFilter: (value: any, row: ILabelV2) => getLabelPostalZone(row) === value,
        filterSearch: true,
      },
      {
        title: 'Assigned To',
        dataIndex: 'dspId',
        key: 'dspId',
        render: (label: string) => {
          if (label !== undefined) {
            return getDspName(label, this.state.dsps);
          }
        },
        filteredValue: filteredFields.dspId || null,
        filters: [{ text: 'Not Assigned', value: 'N/A' }].concat(
          uniq(this.state.dsps.filter((dsp) => dsp.name !== ''))
            .sort()
            .map((dsp: IDeliveryServicePartner) => ({ text: dsp.name, value: dsp.id }))
        ),
        onFilter: (value: any, row: ILabelV2) => {
          let dspId = 'N/A';
          if (row.dspId) {
            dspId = row.dspId;
          }
          return dspId === value;
        },
        filterSearch: true,
      },
      {
        title: 'Manifested To',
        dataIndex: ['dspManifestInformation', 'dspId'],
        key: 'sentDspId',
        render: (label: string) => {
          if (label !== undefined) {
            return getDspName(label, this.state.dsps);
          }
        },
        filteredValue: filteredFields.sentDspId || null,
        filters: [{ text: 'N/A', value: 'N/A' }].concat(
          uniq(this.state.dsps.filter((dsp) => dsp.name !== ''))
            .sort()
            .map((dsp: IDeliveryServicePartner) => ({ text: dsp.name, value: dsp.id }))
        ),
        onFilter: (value: any, row: ILabelV2) => {
          let dspId = 'N/A';
          if (row.dspManifestInformation) {
            dspId = row.dspManifestInformation.dspId;
          }
          return dspId === value;
        },
        filterSearch: true,
      },
      {
        title: 'Scanned By',
        dataIndex: 'scannedBy',
        key: 'scannedBy',
        render: (label: string) => {
          if (label !== undefined) {
            return <GetDspNames value={label} dsps={this.state.dsps} />;
          }
        },
        filteredValue: filteredFields.scannedBy || null,
        filters: uniq(this.state.dsps.filter((dsp) => dsp.name !== ''))
          .sort()
          .map((dsp: IDeliveryServicePartner) => ({ text: dsp.name, value: dsp.id })),
        onFilter: (value: any, row: ILabelV2) => {
          if (row.scannedBy?.includes(value)) return true;
          return false;
        },
        filterSearch: true,
      },
      // {
      //   title: 'Service Type',
      //   dataIndex: 'serviceType',
      //   key: 'serviceType',
      //   filteredValue: filteredFields.serviceType || null,
      //   filters: uniq(this.state.labels.map((label) => capitalize(label.serviceType)))
      //     .sort()
      //     .map((serviceType: string) => ({ text: serviceType, value: serviceType })),
      //   onFilter: (value: any, row: ILabelV2) => capitalize(row.serviceType) === value,
      // },
      {
        title: 'City/Town',
        dataIndex: ['destination', 'address', 'city'],
        key: 'city',
        filteredValue: filteredFields.city || null,
        filters: uniq(this.state.labels.map((label) => capitalize(label.destination.address.city)))
          .sort()
          .map((city: string) => ({ text: city, value: city })),
        onFilter: (value: any, row: ILabelV2) => capitalize(row.destination.address.city) === value,
        filterSearch: true,
      },
      {
        title: 'State/Province',
        dataIndex: ['destination', 'address', 'province'],
        key: 'state/Province',
        filteredValue: filteredFields['state/Province'] || null,
        filters: uniq(this.state.labels.map((label) => label.destination.address.province))
          .sort()
          .map((province: string) => ({ text: province, value: province })),
        onFilter: (value: any, row: ILabelV2) => row.destination.address.province === value,
        filterSearch: true,
      },
      {
        title: 'Action',
        key: 'delete',
        render: (row: ILabelV2) => (
          <Space size="middle">
            <Button
              type="default"
              size="small"
              icon={<PrinterTwoTone />}
              onClick={() => this.printLabels([row])}
            >
              Print
            </Button>
            <Button
              type="default"
              size="small"
              icon={<FullscreenOutlined />}
              onClick={() => this.showLabelDetailsDrawer(row)}
            >
              View
            </Button>
            <Button
              type="default"
              size="small"
              icon={<EditTwoTone />}
              onClick={() => this.showEditModal(row)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this label?"
              onConfirm={() => this.handleDeleteLabels([row])}
            >
              <Button type="default" size="small" icon={<DeleteTwoTone twoToneColor="red" />}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
  };

  sortLabelsByIngestionId = (labels: ILabelV2[]) => {
    return labels.sort((a, b) => ((a.ingestionId as string) > (b.ingestionId as string) ? 1 : -1));
  };

  handleSearch = async () => {
    this.setState({ isLoadingTable: true });

    try {
      const {
        dateRangeStart,
        dateRangeEnd,
        selectedMerchantSlugs,
        selectedCrossdockId,
        selectedDspId,
        selectedTrackingEvents,
        timezone, 
        filterOption
      } = this.state;
      let labels;
      
      if (filterOption === FilterOption.shipDate) {
        labels = await getLabelsForShipDateRangeAndSlugs(
          dateRangeStart.format(DATE_FORMAT.SHIP_DATE),
          dateRangeEnd.format(DATE_FORMAT.SHIP_DATE),
          selectedMerchantSlugs,
          selectedCrossdockId,
          selectedDspId
        );
      } else {
        labels = await getLabelsForTrackingEventStatuses(
          dateRangeStart.format(DATE_FORMAT.SHIP_DATE),
          dateRangeEnd.format(DATE_FORMAT.SHIP_DATE),
          timezone,
          selectedMerchantSlugs,
          selectedTrackingEvents,
          undefined,
          undefined
        );
      }

      const sortedLabels = this.sortLabelsByIngestionId(labels);

      let trackingProblemEvents: { [key: string]: ITrackingEvent } = {};
      const problemLabels = sortedLabels.filter(
        (label) => label.state === TrackingEventState.PROBLEM
      );
      for (const label of problemLabels) {
        const problemEvents = await this.getProblemTrackingEvents(label);
        if (problemEvents.length > 0) {
          trackingProblemEvents[label.id] = problemEvents[problemEvents.length - 1];
        }
      }

      this.setState({
        isLoadingTable: false,
        labels: sortedLabels,
        trackingProblemEvents,
        filteredLabels: sortedLabels,
        filteredFields: {},
        selectedLabels: [],
        labelsDateStart: dateRangeStart.clone(),
        labelsDateEnd: dateRangeEnd.clone(),
      });
    } catch (error) {
      message.error(`Error happened while searching labels for ship date. Error: ${error}`);
      console.error(error);
      this.setState({ isLoadingTable: false });
    }
  };

  handleDeleteLabels = async (labels: ILabelV2[]) => {
    try {
      await Promise.all(labels.map(({ id }) => FUNCTIONS.deleteLabel(id)));

      message.success(`Deleted ${labels.length} labels!`);

      this.handleSearch();
    } catch (error) {
      message.error(`Error happened while deleting labels. Error: ${error}`);
      console.error(error);
    }
  };

  handleStatePassedLabelIds = async () => {
    this.setState({ isLoadingTable: true });

    try {
      const labelIds = get(this.props, ['location', 'state', 'labelIds'], []);

      const labels: ILabelV2[] = await Promise.all(
        labelIds.map((labelId: string) => getLabelById(labelId))
      );

      const sortedLabels = this.sortLabelsByIngestionId(labels);

      const labelDates = labels.map((label) => label.shipDate).sort();

      this.setState({
        isLoadingTable: false,
        labels: sortedLabels,
        filteredLabels: sortedLabels,
        filteredFields: {},
        selectedLabels: [],
        labelsDateStart: moment(labelDates[0]),
        labelsDateEnd: moment(labelDates[labelDates.length - 1]),
      });
    } catch (error) {
      message.error(`Error happened while fetching labels by id. Error: ${error}`);
      console.error(error);
      this.setState({ isLoadingTable: false });
    }
  };

  handleLabelChange = () => {
    this.handleSearch();
  };

  handleTableChange = (_pagination: any, filters: any, _sorter: any, extra: any) => {
    this.setState({
      filteredLabels: extra.currentDataSource,
      filteredFields: filters,
      selectedLabels: [],
    });
  };

  printLabels = async (labels: ILabelV2[]) => {
    this.setState({ isPrintingLabels: true });

    const labelDates = labels.map((label) => label.shipDate).sort();
    const merchantSlugs = uniq(labels.map((label) => label.merchantSlug));

    try {
      const labelIds = labels.map((label) => label.id);
      const result = await FUNCTIONS.printPdfLabels({ labelIds });
      const link = document.createElement('a');
      link.href = result.data;
      link.target = '_blank';
      const fileName = `${labelDates[0]}_${labelDates[labelDates.length - 1]}_${merchantSlugs.join(
        '+'
      )}.pdf`;
      link.download = fileName;
      link.click();
      message.success('Downloaded PDF');
    } catch (err) {
      message.error('Something went wrong. Unable to print labels');
    }

    this.setState({ isPrintingLabels: false });
  };

  downloadLabelsAsCSV = (labels: ILabelV2[]) => {
    try {
      this.setState({ isDownloadingCsv: true });
      exportLabelsCSV(
        'sway_labels.csv',
        labels,
        this.state.dsps,
        () => {
          message.success('Downloaded CSV');
          this.setState({ isDownloadingCsv: false });
        },
        () => {
          message.error('Error downloading csv');
          this.setState({ isDownloadingCsv: false });
        }
      );
    } catch (err) {
      message.error('Something went wrong. Unable to download csv');
      this.setState({ isDownloadingCsv: false });
    }
  };

  setNewShipDate = (shipDate: Moment | null) => {
    this.setState({ rescheduleShipDate: shipDate || moment() });
  };

  setNewLabelsState = (labelState: TrackingEventState | null) => {
    this.setState({ modifyLabelsState: labelState || TrackingEventState.NOT_RECEIVED });
  };

  showRescheduleLabelsModal = () => {
    this.setState({ isRescheduleLabelsModalVisible: true });
  };

  hideRescheduleLabelsModal = () => {
    this.setState({
      isRescheduleLabelsModalVisible: false,
      rescheduleLabelErrors: [],
      rescheduleShipDate: moment(),
    });
  };

  rescheduleLabels = async () => {
    this.setState({ isReschedulingLabels: true });
    const { rescheduleShipDate, filteredLabels, selectedLabels } = this.state;
    const activeLabels = selectedLabels.length ? selectedLabels : filteredLabels;
    try {
      const { data: rescheduledLabels } = await FUNCTIONS.rescheduleLabels({
        labelIds: activeLabels.map(getLabelId),
        shipDate: rescheduleShipDate.format(DATE_FORMAT.SHIP_DATE),
      });

      const rescheduleLabelErrors = rescheduledLabels.filter((res: any) => res.error);

      if (rescheduledLabels.length !== rescheduleLabelErrors.length) {
        message.success(
          `Successfully updated ship date for the labels (${
            rescheduledLabels.filter((res: any) => res.data).length
          }).`
        );
      }

      this.setState({
        rescheduleLabelErrors,
        dateRangeStart: rescheduleShipDate,
        dateRangeEnd: rescheduleShipDate,
      });

      // reloading table info
      this.handleSearch();
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while trying to update ship date for the labels.');
    }

    this.setState({ isReschedulingLabels: false });
  };

  showModifyLabelsStateModal = () => {
    this.setState({ isModifyLabelsStateModalVisible: true });
  };

  hideModifyLabelsStateModal = () => {
    this.setState({
      isModifyLabelsStateModalVisible: false,
      modifyLabelsState: TrackingEventState.NOT_RECEIVED,
    });
  };

  modifyLabelsState = async () => {
    this.setState({ isModifyingLabelsState: true });
    const { modifyLabelsState, selectedLabels } = this.state;
    try {
      await Promise.all(
        selectedLabels.map((label) =>
          FUNCTIONS.updateLabelState({ labelId: getLabelId(label), state: modifyLabelsState })
        )
      );
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while trying to update state for the labels.');
    } finally {
      // reloading table info
      this.handleSearch();
      this.setState({ isModifyingLabelsState: false });
    }
  };

  showAssignToDspModal = () => {
    this.setState({ isAssignToDspModalVisible: true });
  };

  hideAssignToDspModal = () => {
    this.setState({
      isAssignToDspModalVisible: false,
    });
  };

  assignToDsp = async () => {
    const { selectedAssignedDspId, selectedLabels } = this.state;
    if (selectedAssignedDspId === '' || selectedAssignedDspId === undefined) {
      message.error('You have to choose a DSP to assign.');
      return;
    }
    const labelIds = selectedLabels.map(getLabelId);
    this.setState({ isAssigningToDsp: true });
    const batchSize =
      labelIds.length >= MIN_ASSIGN_TO_DSP_BATCH
        ? Math.ceil(labelIds.length / ASSIGN_TO_DSP_BATCH_COUNT)
        : labelIds.length;
    const allLabelIds = chunk(labelIds, batchSize);
    try {
      await Promise.all(
        allLabelIds.map((labelIds: string[]) =>
          FUNCTIONS.assignLabelsToDsp({
            labelIds,
            dspId: selectedAssignedDspId,
          })
        )
      );
      this.hideAssignToDspModal();
      message.success('Labels assigned to DSP');
    } catch (err) {
      console.error(err);
      message.error('Something went wrong while trying to assign labels to DSP.');
    } finally {
      // reloading table info
      this.handleSearch();
      this.setState({ isAssigningToDsp: false });
    }
  };

  handleSearchByLabelId = async (labelId: string) => {
    this.setState({ isLoadingTable: true });

    try {
      const label = await getLabelById(labelId);

      let trackingProblemEvents: { [key: string]: ITrackingEvent } = {};
      if (label.state === TrackingEventState.PROBLEM) {
        const problemEvents = await this.getProblemTrackingEvents(label);
        if (problemEvents.length > 0) {
          trackingProblemEvents[label.id] = problemEvents[problemEvents.length - 1];
        }
      }

      this.setState({
        labels: [label],
        filteredLabels: [label],
        filteredFields: {},
        selectedLabels: [],
        trackingProblemEvents,
        labelsDateStart: moment(label.shipDate),
        labelsDateEnd: moment(label.shipDate),
      });
    } catch (err) {
      message.error(`Something went wrong while trying to get label data. Error: ${err}`);
      console.error(err);
    }

    this.setState({ isLoadingTable: false });
  };

  showEditModal = (label: ILabelV2) => {
    this.setState({ isEditModalVisible: true, selectedLabel: label });
  };

  hideEditModal = () => {
    this.setState({ isEditModalVisible: false });
  };

  showLabelDetailsDrawer = (label: ILabelV2) => {
    this.setState({ isLabelDetailsDrawerVisible: true, selectedLabel: label });
  };

  hideLabelDetailsDrawer = () => {
    this.setState({ isLabelDetailsDrawerVisible: false });
  };

  showLabelsDeletionConfirmModal = () => {
    const { filteredLabels, selectedLabels } = this.state;
    const activeLabels = selectedLabels.length ? selectedLabels : filteredLabels;

    confirm({
      title: `Are you sure delete ${activeLabels.length} labels?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => this.handleDeleteLabels(activeLabels),
    });
  };

  onLabelSelectionChange = (_selectedRowKeys: any, selectedRows: any) => {
    this.setState({ selectedLabels: selectedRows });
  };

  handleSetDay = (date: Moment | null) => {
    if (date) {
      this.setState({
        dateRangeStart: date.clone().startOf('day'),
        dateRangeEnd: date.clone().endOf('day'),
      });
    }
  };

  handleSetWeek = (date: Moment | null) => {
    if (date) {
      this.setState({
        dateRangeStart: date.clone().startOf('isoWeek'),
        dateRangeEnd: date.clone().endOf('isoWeek'),
      });
    }
  };

  handleSetMonth = (date: Moment | null) => {
    if (date) {
      this.setState({
        dateRangeStart: date.clone().startOf('month'),
        dateRangeEnd: date.clone().endOf('month'),
      });
    }
  };

  handleSetCustomDateRange = (range: any) => {
    if (range[0] && range[1]) {
      this.setState({
        dateRangeStart: range[0],
        dateRangeEnd: range[1],
      });
    }
  };

  handleDateChange = (duration: number, unit: unitOfTime.Base) => {
    const { dateRangeStart, dateRangeEnd } = this.state;

    this.setState({
      dateRangeStart: dateRangeStart.clone().add(duration, unit),
      dateRangeEnd: dateRangeEnd.clone().add(duration, unit),
    });
  };

  handlePrevDay = () => {
    this.handleDateChange(-1, 'day');
  };

  handleNextDay = () => {
    this.handleDateChange(1, 'day');
  };

  handlePrevWeek = () => {
    this.handleDateChange(-1, 'week');
  };

  handleNextWeek = () => {
    this.handleDateChange(1, 'week');
  };

  handlePrevMonth = () => {
    this.handleDateChange(-1, 'month');
  };

  handleNextMonth = () => {
    this.handleDateChange(1, 'month');
  };

  handleSetDateRangeType = (type: string) => {
    switch (type) {
      case DATE_RANGE_TYPE.DATE:
        this.handleSetDay(moment());
        break;
      case DATE_RANGE_TYPE.WEEK:
        this.handleSetWeek(moment());
        break;
      case DATE_RANGE_TYPE.MONTH:
        this.handleSetMonth(moment());
        break;
      default:
    }

    this.setState({ dateRangeType: type as DATE_RANGE_TYPE });
  };

  renderDatePickerTabPane = (
    dateRangeType: DATE_RANGE_TYPE,
    dateRangeStart: Moment,
    handlePrevDate: any,
    handleNextDate: any,
    handleSetDate: any
  ) => (
    <TabPane tab={startCase(dateRangeType)} key={dateRangeType}>
      <Button icon={<LeftOutlined />} onClick={handlePrevDate} />
      <DatePicker
        style={{ flex: 1 }}
        value={dateRangeStart}
        format={DATE_FORMAT.READABLE_SHIP_DATE}
        onChange={handleSetDate}
        // @ts-ignore TS complains that provided value does not match internal type of library
        picker={dateRangeType}
        allowClear={false}
      />
      <Button icon={<RightOutlined />} onClick={handleNextDate} />
    </TabPane>
  );

  render() {
    const {
      labels,
      isLoadingTable,
      rescheduleShipDate,
      modifyLabelsState,
      selectedLabel,
      isEditModalVisible,
      isLabelDetailsDrawerVisible,
      isPrintingLabels,
      isDownloadingCsv,
      isRescheduleLabelsModalVisible,
      isReschedulingLabels,
      rescheduleLabelErrors,
      isModifyingLabelsState,
      isModifyLabelsStateModalVisible,
      isAssigningToDsp,
      isAssignToDspModalVisible,
      selectedLabels,
      filteredLabels,
      dateRangeStart,
      dateRangeEnd,
      dateRangeType,
      labelsDateStart,
      labelsDateEnd,
    } = this.state;

    const activeLabels = selectedLabels.length ? selectedLabels : filteredLabels;

    return (
      <>
        <Row>
          <Col span={24}>
            <FuzzySearchBar onSelectLabel={this.handleSearchByLabelId} />
          </Col>
        </Row>

        <Row align="bottom" style={{ margin: '10px', marginLeft: '0px' }}>
          <Col>
            <Tabs activeKey={dateRangeType} onChange={this.handleSetDateRangeType}>
              {this.renderDatePickerTabPane(
                DATE_RANGE_TYPE.DATE,
                dateRangeStart,
                this.handlePrevDay,
                this.handleNextDay,
                this.handleSetDay
              )}
              {this.renderDatePickerTabPane(
                DATE_RANGE_TYPE.WEEK,
                dateRangeStart,
                this.handlePrevWeek,
                this.handleNextWeek,
                this.handleSetWeek
              )}
              {this.renderDatePickerTabPane(
                DATE_RANGE_TYPE.MONTH,
                dateRangeStart,
                this.handlePrevMonth,
                this.handleNextMonth,
                this.handleSetMonth
              )}
              <TabPane tab="Range" key={DATE_RANGE_TYPE.CUSTOM} style={{ width: '100%' }}>
                <Space size="large" wrap style={{ margin: "10px" }}>
                  <RangePicker
                    showTime
                    value={[dateRangeStart, dateRangeEnd]}
                    format={'MMMM Do, YYYY'}
                    onCalendarChange={this.handleSetCustomDateRange}
                    allowClear={false}
                  />
                </Space>
              </TabPane>
            </Tabs>
            </Col>
            <Col>
            <Space align="end" size="large" wrap style={{ marginTop: 10 }}>
            {this.state.filterOption === FilterOption.trackingEvent ? (
                <Space  align="end" size="large" wrap >
                  <TimezoneSelector onChange={this.setTimezone}/>
                  <MerchantSlugSelector onChange={this.setSelectedMerchantSlugs} />
                  <StatusDateSelector onChange={this.setSelectedStatusDateEvent} />
                </Space>
              ) : (
                <Space align="end"  size="large" wrap >
                  <MerchantSlugSelector onChange={this.setSelectedMerchantSlugs} />
                  {/* <CrossdockSelector onChange={this.setSelectedCrossdockId} /> */}
                  <DspSelector onChange={this.setSelectedDspId} />
                  <StatusDateSelector onChange={this.setSelectedStatusDateEvent} />
                </Space>
              )}
                <Button type="primary" onClick={this.handleSearch} icon={<FileSearchOutlined />}>
                  Search
                </Button>
              </Space>
        
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {labels.length > 0 && (
              <div
                style={{
                  margin: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Space size={100}>
                  <Statistic
                    title="Selected date range"
                    valueStyle={{ color: blue.primary }}
                    value={`${labelsDateStart.format('ddd, MMM DD, YYYY')} - ${labelsDateEnd.format(
                      'ddd, MMM DD, YYYY'
                    )}`}
                  />
                  <Statistic
                    title="# Labels"
                    valueStyle={{ color: blue.primary }}
                    value={`${activeLabels.length} of ${labels.length}`}
                  />
                </Space>
                <Space>
                  <Button
                    type="primary"
                    size="middle"
                    loading={isReschedulingLabels}
                    icon={<FieldTimeOutlined />}
                    onClick={this.showRescheduleLabelsModal}
                  >
                    Change ship date
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      background: 'green',
                      borderColor: 'green',
                    }}
                    size="middle"
                    loading={isModifyingLabelsState}
                    icon={<DeliveredProcedureOutlined />}
                    onClick={this.showModifyLabelsStateModal}
                  >
                    Change label state
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    loading={isAssigningToDsp}
                    onClick={this.showAssignToDspModal}
                  >
                    Assign to DSP
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    loading={isPrintingLabels}
                    icon={<PrinterOutlined />}
                    onClick={() => this.printLabels(activeLabels)}
                  >
                    Print Labels
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    icon={<DownloadOutlined />}
                    loading={isDownloadingCsv}
                    onClick={() => this.downloadLabelsAsCSV(activeLabels)}
                  >
                    Download as CSV
                  </Button>
                  <Button
                    danger
                    type="primary"
                    size="middle"
                    loading={isPrintingLabels}
                    icon={<DeleteOutlined />}
                    onClick={this.showLabelsDeletionConfirmModal}
                  >
                    Delete Labels
                  </Button>
                </Space>
              </div>
            )}
            <Modal
              title="Reschedule Labels"
              visible={isRescheduleLabelsModalVisible}
              onCancel={this.hideRescheduleLabelsModal}
              okText="Reschedule"
              onOk={this.rescheduleLabels}
              confirmLoading={isReschedulingLabels}
              style={{
                maxHeight: '80%',
              }}
              width="50%"
            >
              <Space
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                Choose a new ship date for the selected labels:
                <DatePicker
                  name="rescheduleShipDate"
                  value={rescheduleShipDate}
                  format={DATE_FORMAT.SHIP_DATE}
                  onChange={this.setNewShipDate}
                />
              </Space>
              <Text
                style={{
                  width: '100%',
                  color: 'red',
                }}
              >
                Please note that this change will affect {activeLabels.length.toString()} label(s)
              </Text>
              {rescheduleLabelErrors.length > 0 && (
                <Table
                  style={{
                    marginTop: 10,
                  }}
                  dataSource={rescheduleLabelErrors}
                  columns={[
                    {
                      title: 'Errors',
                      render: (row) => JSON.stringify(row.error),
                    },
                  ]}
                  scroll={{
                    y: 500,
                  }}
                />
              )}
            </Modal>
            <Modal
              title="Modify Labels state"
              visible={isModifyLabelsStateModalVisible}
              onCancel={this.hideModifyLabelsStateModal}
              okText="Modify Labels State"
              onOk={this.modifyLabelsState}
              confirmLoading={isModifyingLabelsState}
              style={{
                maxHeight: '80%',
              }}
              width="50%"
            >
              <Space
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                Choose a new state for the selected labels:
                <Select value={modifyLabelsState} onChange={this.setNewLabelsState}>
                  {getTrackingEventStateOptions()}
                </Select>
              </Space>
              <Text
                style={{
                  width: '100%',
                  color: 'red',
                }}
              >
                Please note that this change will affect {selectedLabels.length.toString()} label(s)
              </Text>
            </Modal>
            <Modal
              title="Assign Labels to DSP"
              visible={isAssignToDspModalVisible}
              onCancel={this.hideAssignToDspModal}
              okText="Assign"
              onOk={this.assignToDsp}
              confirmLoading={isAssigningToDsp}
              style={{
                maxHeight: '80%',
              }}
              width="50%"
            >
              <Space
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                Choose a DSP for the selected labels:
                <DspSelector onChange={this.setSelectedAssignedDspId} />
              </Space>
              <Text
                style={{
                  width: '100%',
                  color: 'red',
                }}
              >
                Please note that this change will affect {selectedLabels.length.toString()} label(s)
              </Text>
            </Modal>
            <Table
              size="small"
              pagination={DEFAULT_PAGINATION_CONFIG}
              rowSelection={{
                selectedRowKeys: selectedLabels.map(getLabelId),
                onChange: this.onLabelSelectionChange,
                selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
              }}
              loading={isLoadingTable}
              dataSource={labels}
              rowKey={(label) => label.id}
              columns={this.getLabelTableColumns()}
              onChange={this.handleTableChange}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <EditLabelModal
          label={selectedLabel}
          isEditModalVisible={isEditModalVisible}
          hideEditModal={this.hideEditModal}
          successCallback={this.handleLabelChange}
        />
        <LabelDetailsDrawer
          label={selectedLabel}
          isVisible={isLabelDetailsDrawerVisible}
          onClose={this.hideLabelDetailsDrawer}
        />
      </>
    );
  }
}

export const Labels = withRouter(LabelsBase);
