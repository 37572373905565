import firebase from 'firebase';
import React, { useState } from 'react';
import SWAY_LOGO_PNG from '../assets/sway-logo.png';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Input, Typography, Row, message } from 'antd';

interface Props {
  handleLogin: (email: string, password: string) => void;
}

interface ILocationState {
  from: string;
}

export const Login: React.FunctionComponent<Props> = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const state = useLocation().state as ILocationState;
  const history = useHistory();
  const isLoggedIn = Boolean(firebase.auth().currentUser);

  const handleLogin = (username: string, password: string): void => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then((_user) => {
        history.push(state?.from || '/');
        message.success('Welcome back!');
      })
      .catch((_error) => {
        message.error(`Invalid credentials`);
      });
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Row
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        backgroundColor: '#ffffff',
        paddingBottom: '50px',
      }}
    >
      <Col
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }}
      >
      <img src={SWAY_LOGO_PNG} style={{ width: 300 }} />
      <Typography.Title level={3} >Label Manager Dashboard</Typography.Title>
      
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="large"
          placeholder="Email"
          prefix={<UserOutlined />}
          style={{ marginBottom: '10px' }}
        />
        <Input.Password
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          size="large"
          placeholder="Password"
          prefix={<EditOutlined />}
          style={{ marginBottom: '10px' }}
        />
        <Button
          onClick={() => handleLogin(email, password)}
          type="primary"
          style={{ width: '100%' }}
        >
          Login
        </Button>
      </Col>
    </Row>
  );
};
