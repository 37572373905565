import { IUserBase } from '@swyft/swyft-common';
import React, { useEffect, useState } from 'react';
import firebase from '../services/firebase';
import * as FirestoreService from '../services/firestore';

export const AuthContext = React.createContext<Context>({ isLoadingContext: true });

export interface Context {
  isLoadingContext: boolean;
  user?: IUserBase;
}

export const AuthProvider = ({ children }: any) => {
  const [context, setContext] = useState({} as Context);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const uid = firebaseUser.uid;

        const fetchUser = async () => {
          try {
            const user = await FirestoreService.getUser(uid);
            setContext({ user: user, isLoadingContext: false });
          } catch (err) {
            /** @todo add error handling screen for this INTT-248 */
            console.log('Something went wrong setting auth: ', err);
          }
        };

        fetchUser();
      }
    });
  }, []);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
