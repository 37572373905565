import firebase from '../services/firebase';
import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Button, Layout, message } from 'antd';
import { useHistory } from 'react-router-dom';

export const Header: React.FunctionComponent = () => {
  const history = useHistory();

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        message.info('Successfully logged out');
        history.push('/login');
      })
      .catch((error) => {
        message.info('Failed to log out');
      });
  };

  return (
    <Layout.Header
      style={{
        paddingLeft: '25px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#5455ee'
      }}
    >
      <img
        src={`/sway_logo.png`}
        alt={`Sway Logo`}
        width={35}
        height={50}
      />
      <div style={{ display: 'flex', flex: 'auto', flexDirection: 'row', justifyContent: 'start' }}>
        <span style={{ color: '#fff', marginLeft: '20px', fontSize: '1rem' }}>
          Sway Label Manager Dashboard
        </span>
      </div>
      <Button type="primary" onClick={logout} icon={<LogoutOutlined />}>
        Logout
      </Button>
    </Layout.Header>
  );
};
