import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import { getAllMerchantSlugs } from '../services/firestore';

interface Props {
  onChange: (merchantSlugs: string[]) => void;
}

/**
 * Reusable component to fetch and select merchant slugs
 * @param onChange Callback for the parent component when a slug is selected / deselected
 */
export const MerchantSlugSelector = ({ onChange }: Props) => {
  const [merchantSlugs, setMerchantSlugs] = useState<string[]>([]);
  const [selectedMerchantSlugs, setSelectedMerchantSlugs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getMerchantSlugs = async () => {
      setIsLoading(true);
      try {
        const slugs = await getAllMerchantSlugs();
        setMerchantSlugs(slugs.sort());
      } catch (err) {
        console.error(err);
        message.error('An error occured when loading merchant slugs! See console.');
      } finally {
        setIsLoading(false);
      }
    };
    getMerchantSlugs();
  }, []);

  const handleMerchantSelect = (selectedSlugs: string[]) => {
    if (selectedSlugs.length > 10) {
      message.error({
        content: 'You can choose a maximum of 10 slugs.',
        className: 'error-msg',
      });
    } else {
      setSelectedMerchantSlugs(selectedSlugs);
      onChange(selectedSlugs);
    }
  };

  return (
    <Select
      placeholder="Merchants"
      mode="multiple"
      options={merchantSlugs.map((slug) => ({ label: slug, value: slug }))}
      filterOption={true}
      loading={isLoading}
      value={selectedMerchantSlugs}
      onChange={handleMerchantSelect}
      allowClear={true}
      style={{ width: 200 }}
    />
  );
};
