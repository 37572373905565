import { Button, Col, Input, message, Row, Form, Modal, Divider } from 'antd';
import { merge, cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ILabelV2 } from '@swyft/swyft-common';
import { FUNCTIONS } from '../services/functions';

interface ISuccessCallbackRequest {
  [key: string]: any;
}

interface Props {
  label: ILabelV2;
  isEditModalVisible: boolean;
  hideEditModal: () => void;
  successCallback?: ({ props }: ISuccessCallbackRequest) => void; // ONLY run this function on modal close if the label was updated
}

export const EditLabelModal = ({
  label,
  isEditModalVisible,
  hideEditModal,
  successCallback,
}: Props) => {
  const [tempLabel, setTempLabel] = useState({} as ILabelV2);
  const [isSavingLabel, setIsSavingLabel] = useState(false);
  const [isRevalidatingAddress, setIsRevalidatingAddress] = useState(false);
  const [isApprovingLabel, setIsApprovingLabel] = useState(false);
  const [wasLabelChanged, setWasLabelChanged] = useState(false);

  useEffect(() => {
    setTempLabel(label);
  }, [label]);

  /**
   * State helper to modify specific nested properties
   * @param source the object path of the property being updated. IE: label.destination.address.line1
   */
  const updateTempLabel = (source: any) => {
    let merged = merge(cloneDeep(tempLabel), source);
    setTempLabel(merged);
  };

  const handleHideEditModal = () => {
    hideEditModal();
    if (wasLabelChanged && successCallback) {
      successCallback(tempLabel);
    }
  };

  /**
   * Upserts label with tempLabel
   */
  const saveTempLabel = async () => {
    setIsSavingLabel(true);

    try {
      const { data } = await FUNCTIONS.updateLabelDataV2({
        labelData: tempLabel,
      });

      setTempLabel(data);
      message.success('Saved label.');
      setWasLabelChanged(true); // Label was changed. We should run the success callback on modal close.
    } catch (err) {
      message.error('Failed to save label. See console.');
      console.error(err);
    }

    setIsSavingLabel(false);
  };



  const handleValidateAddress = async () => {
    setIsRevalidatingAddress(true);

    try {
      const { data } = await FUNCTIONS.updateAndValidateDestinationAddress({
        labelId: tempLabel.id,
        address: tempLabel.destination.address,
        notes: tempLabel.notes,
      });

      message.success('Successfully updated and validated the address!');

      setWasLabelChanged(true);
      updateTempLabel(data);
    } catch (err) {
      message.error('Failed to save and validate address. See console.');
      console.error(err);
    }

    setIsRevalidatingAddress(false);
  };

  /**
   * Manually approves the label
   */
  const approveLabel = async () => {
    setIsApprovingLabel(true);

    const labelIds = [tempLabel.id];

    try {
      await FUNCTIONS.manuallyApproveLabels({ labelIds });
      message.success('Approved the label.');
      setWasLabelChanged(true);
    } catch (err) {
      message.error('Failed to approve the label. See console.');
      console.error(err);
    }
    setIsApprovingLabel(false);
  };

  return (
    <Modal
      title="Edit Label"
      visible={isEditModalVisible}
      width={800}
      onCancel={handleHideEditModal}
      footer={[
        <Button key="back" onClick={handleHideEditModal}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={saveTempLabel} loading={isSavingLabel}>
          Save
        </Button>,
        <Button type="primary" danger onClick={approveLabel} loading={isApprovingLabel}>
          Approve
        </Button>,
      ]}
    >
      <Divider orientation="left"> Label Info </Divider>
      <Form labelCol={{ span: 8 }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Merchant">
              <Input readOnly disabled value={tempLabel?.merchantSlug} />
            </Form.Item>
            <Form.Item label="Tracking #">
              <Input readOnly disabled value={tempLabel?.trackingNumber} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Label ID">
              <Input readOnly disabled value={tempLabel?.id} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider orientation="left"> Recipient </Divider>
      <Form labelCol={{ span: 6 }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="First name">
              <Input
                value={tempLabel?.destination?.firstName}
                onChange={(e) => updateTempLabel({ destination: { firstName: e.target.value } })}
              />
            </Form.Item>
            <Form.Item label="Phone">
              <Input
                value={tempLabel?.destination?.phone}
                onChange={(e) => updateTempLabel({ destination: { phone: e.target.value } })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last name">
              <Input
                value={tempLabel?.destination?.lastName}
                onChange={(e) => updateTempLabel({ destination: { lastName: e.target.value } })}
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                value={tempLabel?.destination?.email}
                onChange={(e) => updateTempLabel({ destination: { email: e.target.value } })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider orientation="left"> Delivery address </Divider>

      <Form labelCol={{ span: 6 }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Line 1">
              <Input
                value={tempLabel?.destination?.address?.line1}
                onChange={(e) =>
                  updateTempLabel({ destination: { address: { line1: e.target.value } } })
                }
              />
            </Form.Item>
            <Form.Item label="Line 2">
              <Input
                value={tempLabel?.destination?.address?.line2}
                onChange={(e) =>
                  updateTempLabel({ destination: { address: { line2: e.target.value } } })
                }
              />
            </Form.Item>
            <Form.Item label="City">
              <Input
                value={tempLabel?.destination?.address?.city}
                onChange={(e) =>
                  updateTempLabel({ destination: { address: { city: e.target.value } } })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Postal code">
              <Input
                value={tempLabel?.destination?.address?.postalCode}
                onChange={(e) =>
                  updateTempLabel({ destination: { address: { postalCode: e.target.value } } })
                }
              />
            </Form.Item>
            <Form.Item label="Province">
              <Input
                value={tempLabel?.destination?.address?.province}
                onChange={(e) =>
                  updateTempLabel({ destination: { address: { province: e.target.value } } })
                }
              />
            </Form.Item>
            <Form.Item label="Country">
              <Input
                value={tempLabel?.destination?.address?.country}
                onChange={(e) =>
                  updateTempLabel({ destination: { address: { country: e.target.value } } })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }} gutter={[10, 0]}>
          <Col>
            <Button type="primary" loading={isRevalidatingAddress} onClick={handleValidateAddress}>
              Revalidate
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
