import React, { useCallback, useState } from 'react';
import { Select, Space } from 'antd';

interface Props {
  onChange: (timezone: string) => void;
}

export const filterTimezoneOptions = {
  Los_Angeles: 'Los Angeles',
  New_York: 'New York',
  Chicago: 'Chicago',
};
export const timezoneMapping = {
    Los_Angeles: 'America/Los_Angeles',
    New_York: 'America/New_York',
    Chicago: 'America/Chicago',
  };

export const TimezoneSelector = ({ onChange }: Props) => {
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    filterTimezoneOptions.Los_Angeles
  );

  const handleSelectTimezone = useCallback((value: string, option: any) => {
    if (value === selectedTimezone) {
      return;
    }
    setSelectedTimezone(value);
    onChange(timezoneMapping[option.value as keyof typeof timezoneMapping]);
  }, []);

  return (
    <>
      <Space direction="horizontal" size="middle">
        timezone:
        <Select
          placeholder="Timezone"
          value={selectedTimezone}
          style={{ width: 180 }}
          options={[...Object.keys(filterTimezoneOptions) as Array<keyof typeof filterTimezoneOptions>].map((opt) => {
            return { label: filterTimezoneOptions[opt], value: opt };
          })}
          onChange={handleSelectTimezone}
        />
      </Space>
    </>
  );
};
