import React, { useContext } from 'react';
import { Layout, Space } from 'antd';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Loading } from './Loading';
import { AuthContext, Context } from '../components/AuthProvider';
import { UserMisconfiguredCard } from '../components/UserMisconfiguredCard';
import { UserType } from '@swyft/swyft-common';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Labels } from './Labels';
import { PrivateRoute } from '../components/PrivateRoute';

const { Content } = Layout;

export const Home: React.FC = () => {
  const { user, isLoadingContext } = useContext<Context>(AuthContext);

  const renderContent = () => {
    if (user && user.type === UserType.INTERNAL) {
      return (
        <Content
          style={{
            minHeight: 'fit-content',
            background: 'white',
          }}
        >
          {isLoadingContext ? (
            <Loading />
          ) : (
            <Router>
              <Layout style={{ minHeight: '100vh' }}>
                <Layout>
                  {/* <Sidebar /> */}
                  <Layout style={{ padding: '24px 24px 24px' }}>
                    <Content
                      style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                        background: 'white',
                      }}
                    >
                      <Switch>
                        <PrivateRoute path="/labels">
                          <Labels />
                        </PrivateRoute>
                        <Redirect from="/" to="/labels" />
                      </Switch>
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Router>
          )}
        </Content>
      );
    } else {
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Space direction="vertical">
            <UserMisconfiguredCard />
          </Space>
        </Layout>
      );
    }
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header />
      <Layout style={{ overflow: 'scroll' }}>{renderContent()}</Layout>)
    </Layout>
  );
};
