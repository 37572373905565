import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, RowProps, Col, Card, Typography } from 'antd';
import { RefAttributes, ComponentType } from 'react';
const { Text } = Typography;

export const UserMisconfiguredCard: ComponentType<
  RowProps & RefAttributes<HTMLDivElement>
> = () => {
  return (
    <Row justify="center">
      <Col span={14}>
        <Card
          title={
            <>
              <ExclamationCircleOutlined />
              <Text> User Misconfigured </Text>
            </>
          }
          style={{ backgroundColor: '#fafafa' }}
        >
          <Row justify="center" align="middle">
            <Col>
              <Text>This user does not have the necessary permissions to view the dashboard.</Text>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Text strong>Please create an internal user via Retool!</Text>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
